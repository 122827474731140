/**
 * currencyFormat returns formatted currency value
 * @param  {string|number} value 18.566
 * @return {string} 18,57
 */
export const currencyFormat = value => {
  if (value === null) {
    return null
  }
  const currencyFormatter = new Intl.NumberFormat("nl", {
    currency: "EUR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  return currencyFormatter.format(value)
}

/**
 * currencyEuro returns formatted currency value with currency sign
 * @param  {string|number} value 18.566
 * @return {string} € 18,57
 */
export const currencyEuro = (value, fraction = 2) => {
  if (value === null) {
    return null
  }
  const posVal = Math.abs(value)
  const currencyFormatter = new Intl.NumberFormat("nl", {
    currency: "EUR",
    style: "currency",
    maximumFractionDigits: 2,
    minimumFractionDigits: fraction,
  })

  return `${value < 0 ? '- ': ''}${currencyFormatter.format(posVal)}`
}
